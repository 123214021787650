




































import { Component, Vue } from "vue-property-decorator";

import SwitchSlider from "@/components/menu/SwitchSlider.vue";

@Component({
  components: {
    SwitchSlider,
  },
})
export default class Settings extends Vue {
  joinRoomId = "";
  createRoomId = "";
  roomSize: number | null = null;
  singleMode: boolean = this.$store.state.co_op.single;

  get message(): string {
    if (this.$store.state.co_op.joined) return `Joined room ${this.$store.state.co_op.room}`;

    const err = this.$store.state.co_op.error;
    if (err) return err;

    return "";
  }

  get link(): string | undefined {
    if (this.$store.state.co_op.joined && this.$store.state.co_op.single) {
      // eslint-disable-next-line no-undef
      const base = process.env.VUE_APP_DOMAIN;
      const game = this.$store.state.settings.game;
      const room = this.$store.state.co_op.room;
      return `${base}/remote/${game}/${room}`;
    }

    return undefined;
  }

  join(_event: Event): void {
    this.$store.dispatch("co_op/join", { room: this.joinRoomId });
  }

  create(_event: Event): void {
    this.$store.dispatch("co_op/create", { size: this.roomSize || 2, single: this.singleMode });
  }
}
